/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "../styles/application"

import "animate.css"
import "bootstrap"

require("chartkick")
require("chart.js")
require("jquery")

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

library.add(fas, faTwitter)
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import Turbolinks from "turbolinks"
Turbolinks.start()
Turbolinks.setProgressBarDelay(300)

import Rails from "rails-ujs"
Rails.start()

// import ActionCable from 'actioncable'
// window.cable = ActionCable.createConsumer()
